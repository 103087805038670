import { Card, CardContent, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { WalletModel } from 'src/common/api'
import { QCADIcon, USDCIcon } from 'src/common/assets'
import { useWallet } from 'src/common/context'
import { formatNumber, trimId } from 'src/common/helpers'
import { CHAIN_NATIVE_TOKEN_ICON, CHAIN_NATIVE_TOKEN, EXPLORER, WALLET_ICON, ChainName } from 'src/common/static'
import * as S from './styles'

interface WalletCardProps {
  wallet: WalletModel
}

const WalletCard: React.FC<WalletCardProps> = ({ wallet }: WalletCardProps) => {
  const { selectedWallet } = useWallet()
  const { id, name, address, balance, chain } = wallet
  const Icon = WALLET_ICON[chain]
  const AssetIcon = CHAIN_NATIVE_TOKEN_ICON[chain]

  const connected = selectedWallet.address === address

  return (
    <Box mt="24px" key={id}>
      <Card elevation={3}>
        <CardContent sx={{ p: '20px' }}>
          <Box display="flex" alignItems="center" mb={3}>
            <Icon fontSize="large" />
            <Typography variant="h5" ml={1.5} width="215px" noWrap>
              {name}
            </Typography>
          </Box>
          <Stack spacing={2} mb={2.5}>
            <Stack direction="row">
              <AssetIcon />
              <Typography flexGrow={1} ml={2}>
                {CHAIN_NATIVE_TOKEN[chain]}
              </Typography>
              <Typography>{balance?.native ? formatNumber(balance.native as number, 2) : 'fetching'}</Typography>
            </Stack>
            <Stack direction="row">
              <QCADIcon />
              <Typography flexGrow={1} ml={2}>
                QCAD
              </Typography>
              <Typography>
                {balance?.qcad
                  ? formatNumber(balance.qcad as number)
                  : balance?.qcad_xlm
                  ? formatNumber(balance.qcad_xlm as number)
                  : 'not opted-in'}
              </Typography>
            </Stack>
            <Stack direction="row">
              <USDCIcon />
              <Typography flexGrow={1} ml={2}>
                USDC
              </Typography>
              <Typography>
                {balance?.usdc
                  ? formatNumber(balance.usdc)
                  : balance?.usdc_xlm
                  ? formatNumber(balance.usdc_xlm)
                  : 'not opted-in'}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center">
            <S.CircleIcon connected={connected} />
            <Typography ml={1} flexGrow={1} variant="body2">
              {connected ? 'Connected' : 'Disconnected'}
            </Typography>
            <Link
              component="a"
              target="_blank"
              rel="noopener"
              href={
                chain != ChainName.STELLAR
                  ? `${EXPLORER[chain]}/address/${address}`
                  : `${EXPLORER[chain]}/account/${address}`
              }
              color="inherit"
              variant="body2"
            >
              {trimId(address)}
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

export default WalletCard
