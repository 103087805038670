import React, { useState } from 'react'
import {
  Typography,
  Stack,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Button,
  Menu,
  MenuItem,
  Link,
  Alert
} from '@mui/material'
import { Box } from '@mui/system'
import { SummaryTable, Modal, alertNotification } from 'src/ui'
import {
  AddBankIcon,
  FlagIcon,
  USFlagIcon,
  QCADIcon,
  USDCIcon,
  EthIcon,
  AlgoIcon,
  StellarIcon,
  QRCodeIcon,
  BankIcon
} from 'src/common/assets'
import { ExpandMore } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {
  BankDetailsModel,
  BillPayDetailsModel,
  BlockchainDetailsModel,
  ContactModel,
  CreateBillPayDetailsDTO,
  CreateBlockchainDetailsDTO,
  deleteContactBankDetails,
  deleteContactBillpayDetails,
  deleteContactBlockchainDetails,
  updateContact,
  updateContactNewBankDetails,
  updateContactNewBillPayDetails,
  updateContactNewBlockchainDetails,
  updateContactSetPrimaryBank
} from '../../api/contacts'
import {
  ValidationError,
  isAchCodeValid,
  isBlockchainAddressValid,
  isEmailValid,
  isInstitutionNumValid,
  isSwiftBicCodeValid,
  isTransitNumValid,
  trimId
} from 'src/common/helpers'
import * as S from './styles'
import { BankingCountry, ChainName, EXPLORER } from 'src/common/static'
import { DeleteContactIcon } from 'src/common/assets/DeleteContactIcon'
import { BlockchainIcon } from 'src/common/assets/BlockchainIcon'
import { countryToAlpha2 } from 'country-to-iso'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

interface ContactProps {
  contact: ContactModel
  bankDetails: BankDetailsModel[]
  blockchainDetails: BlockchainDetailsModel[]
  billPayDetails: BillPayDetailsModel[]
  isBillPayEnabled: boolean
}

interface newBankModel {
  country: BankingCountry
  name: string
  accHolderName: string
  institutionNum: string
  transitNum: string
  routingNum: string
  accountNum: string
  achCode: string
  swiftBicCode: string
}

const Contact: React.FC<ContactProps> = ({
  contact,
  bankDetails,
  blockchainDetails,
  billPayDetails,
  isBillPayEnabled
}: ContactProps) => {
  const [isEdit, setIsEdit] = useState(false)
  const [values, setValues] = useState({
    id: contact.id,
    firstName: contact.firstName,
    middleName: contact.middleName ? contact.middleName : '',
    lastName: contact.lastName,
    email: contact.email,
    companyTitle: contact.companyTitle ? contact.companyTitle : '',
    addressLine1: contact.address.includes(',')
      ? contact.address.substring(0, contact.address.indexOf(','))
      : contact.address,
    addressLine2: contact.address.includes(',')
      ? contact.address.substring(contact.address.indexOf(',') + 1, contact.address.length).trim()
      : '',
    postalCode: contact.postalCode,
    countryCode: contact.countryCode,
    city: contact.city,
    stateProvince: contact.stateProvince
  })
  const noErrors = {
    firstName: false,
    middleName: false,
    lastName: false,
    email: false,
    companyTitle: false,
    addressLine1: false,
    addressLine2: false,
    postalCode: false,
    countryCode: false,
    city: false,
    stateProvince: false,
    institutionNum: false,
    transitNum: false,
    bankName: false,
    accHolderName: false,
    accountNum: false,
    achCode: false,
    swiftBicCode: false,
    name: false,
    address: false,
    payeeCode: false,
    payeeName: false,
    payeeAccountNumber: false
  }
  const [errors, setErrors] = useState(noErrors)
  const [bankIdx, setBankIdx] = useState<number>(0)
  const [banks, setBanks] = useState(
    bankDetails.map((b) => ({
      id: b.id,
      country: b.country,
      name: b.name,
      institutionNum: b.routingNum.substring(0, 3),
      transitNum: b.routingNum.substring(4),
      routingNum: b.routingNum,
      accountNum: b.accountNum,
      accHolderName: b.accHolderName,
      isPrimary: b.isPrimary,
      achCode: b.achCode,
      swiftBicCode: b.swiftBicCode
    }))
  )
  const [newBankIdx, setNewBankIdx] = useState<number>(0)
  const [newBanks, setNewBanks] = useState<newBankModel[]>([])
  const [blockchainIdx, setBlockchainIdx] = useState<number>(0)
  const [blockchains, setBlockchains] = useState<BlockchainDetailsModel[]>(blockchainDetails)
  const [newBlockchainIdx, setNewBlockchainIdx] = useState<number>(0)
  const [newBlockchains, setNewBlockchains] = useState<CreateBlockchainDetailsDTO[]>([])
  const [anchorElChain, setAnchorElChain] = useState<null | HTMLElement>(null)
  const [anchorElCountry, setAnchorElCountry] = useState<null | HTMLElement>(null)
  const [showQR, setShowQR] = useState<boolean>(false)
  const [targetNew, setTargetNew] = useState<boolean>(false)

  const [billpayIdx, setBillpayIdx] = useState<number>(0)
  const [billpays, setBillpays] = useState<BillPayDetailsModel[]>(billPayDetails)
  const [newBillpayIdx, setNewBillpayIdx] = useState<number>(0)
  const [newBillpays, setNewBillpays] = useState<CreateBillPayDetailsDTO[]>([])
  const [updatedBillpays, setUpdatedBillpays] = useState<BillPayDetailsModel[]>([])

  const chooseChain = (chain: ChainName) => {
    const updatedBlockchainDetails = {
      ...blockchains[blockchainIdx],
      chain: chain
    }
    setBlockchains(blockchains.map((wallet, idx) => (idx === blockchainIdx ? updatedBlockchainDetails : wallet)))
    handleCloseChainMenu()
  }

  const handleOpenChainMenu = (event: React.MouseEvent<HTMLElement>, idx: number) => {
    setBlockchainIdx(idx)
    setAnchorElChain(event.currentTarget)
  }

  const handleCloseChainMenu = () => {
    setAnchorElChain(null)
  }

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLElement>, isNew: boolean, idx: number) => {
    if (isNew) {
      setTargetNew(true)
      setNewBankIdx(idx)
    } else {
      setTargetNew(false)
      setBankIdx(idx)
    }
    setAnchorElCountry(event.currentTarget)
  }

  const handleCloseCountryMenu = () => {
    setAnchorElCountry(null)
  }

  const chooseCountry = (country: BankingCountry) => {
    if (targetNew) {
      const updatedBankDetails = {
        ...newBanks[newBankIdx],
        country: country
      }
      setNewBanks(newBanks.map((bank, idx) => (idx === newBankIdx ? updatedBankDetails : bank)))
    } else {
      const updatedBankDetails = {
        ...banks[bankIdx],
        country: country
      }
      setBanks(banks.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
    }
    handleCloseCountryMenu()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValues({
      ...values,
      [event.target.name]: newValue
    })
  }

  const handleBankDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBankDetails = {
      ...banks[bankIdx],
      [event.target.name]: event.target.value
    }
    setBanks(banks.map((bank, idx) => (idx === bankIdx ? updatedBankDetails : bank)))
  }

  const handleSetAsPrimary = async (idx: number) => {
    try {
      const response = await updateContactSetPrimaryBank({
        contactId: values.id,
        bankingDetailsId: banks[idx].id
      })
      if (response) {
        setBanks(
          response.data.map((b) => ({
            id: b.id,
            country: b.country,
            name: b.name,
            institutionNum: b.routingNum.substring(0, 3),
            transitNum: b.routingNum.substring(4),
            routingNum: b.routingNum,
            accountNum: b.accountNum,
            accHolderName: b.accHolderName,
            isPrimary: b.isPrimary,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
        )
      }
      alertNotification('Primary bank updated!', 'success')
    } catch {
      alertNotification('Changing primary bank failed')
    }
  }

  const handleBillpayDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    // Update the billpays state
    const updatedBillPayDetails = {
      ...billpays[billpayIdx],
      [name]: value
    }
    setBillpays(billpays.map((billpay, idx) => (idx === billpayIdx ? updatedBillPayDetails : billpay)))

    // Track the updated bill pay details
    setUpdatedBillpays((prev) => {
      const existing = prev.find((item) => item.id === billpays[billpayIdx].id)
      if (existing) {
        return prev.map((item) => (item.id === billpays[billpayIdx].id ? { ...item, [name]: value } : item))
      } else {
        return [...prev, { ...billpays[billpayIdx], [name]: value }]
      }
    })
  }

  const isValidBillpayDetails = (): boolean => {
    for (let i = 0; i < billpays.length; i++) {
      const billpay = billpays[i]
      if (billpay.payeeCode && isNaN(Number(billpay.payeeCode))) {
        return false
      } else if (billpay.payeeAccountNumber && isNaN(Number(billpay.payeeAccountNumber))) {
        return false
      }
    }
    return true
  }

  const isMissingBillpayDetails = (): boolean => {
    for (let i = 0; i < billPayDetails.length; i++) {
      const billpay = billPayDetails[i]
      if (i === 0 && !billpay.payeeName && !billpay.payeeCode && !billpay.payeeAccountNumber) {
        continue
      }
      if (
        (billpay.payeeName || billpay.payeeCode || billpay.payeeAccountNumber) &&
        (!billpay.payeeName || !billpay.payeeCode || !billpay.payeeAccountNumber)
      ) {
        return true
      }
    }
    return false
  }

  const handleNewBillpayDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBillPayDetails = {
      ...newBillpays[newBillpayIdx],
      [event.target.name]: event.target.value
    }
    setNewBillpays(newBillpays.map((billpay, idx) => (idx === newBillpayIdx ? updatedBillPayDetails : billpay)))
  }

  const handleNewBankDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBankDetails = {
      ...newBanks[newBankIdx],
      [event.target.name]: event.target.value
    }
    setNewBanks(newBanks.map((bank, idx) => (idx === newBankIdx ? updatedBankDetails : bank)))
  }

  const handleBlockchainDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBlockchainDetails = {
      ...blockchains[blockchainIdx],
      [event.target.name]: event.target.value
    }
    setBlockchains(blockchains.map((wallet, idx) => (idx === blockchainIdx ? updatedBlockchainDetails : wallet)))
  }

  const handleNewBlockchainDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBlockchainDetails = {
      ...newBlockchains[newBlockchainIdx],
      [event.target.name]: event.target.value
    }
    setNewBlockchains(
      newBlockchains.map((wallet, idx) => (idx === newBlockchainIdx ? updatedBlockchainDetails : wallet))
    )
  }

  const [blockchainOpen, setBlockchainOpen] = useState(false)
  const [billpayOpen, setBillpayOpen] = useState(false)

  const isMissingBankDetails = (): boolean => {
    for (let i = 0; i < banks.length; i++) {
      const bank = banks[i]
      if (
        bank.country === BankingCountry.CAN &&
        (bank.name || bank.institutionNum || bank.transitNum || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.institutionNum || !bank.transitNum || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      } else if (
        bank.country === BankingCountry.US &&
        (bank.name || bank.achCode || bank.swiftBicCode || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.achCode || !bank.swiftBicCode || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      }
    }
    for (let i = 0; i < newBanks.length; i++) {
      const bank = newBanks[i]
      if (
        bank.country === BankingCountry.CAN &&
        (bank.name || bank.institutionNum || bank.transitNum || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.institutionNum || !bank.transitNum || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      } else if (
        bank.country === BankingCountry.US &&
        (bank.name || bank.achCode || bank.swiftBicCode || bank.accountNum || bank.accHolderName) &&
        (!bank.name || !bank.achCode || !bank.swiftBicCode || !bank.accountNum || !bank.accHolderName)
      ) {
        return true
      }
    }
    return false
  }

  const isValidBankDetails = () => {
    const isValid = {
      institutionNum: true,
      transitNum: true,
      achCode: true,
      swiftBicCode: true
    }
    for (let i = 0; i < banks.length; i++) {
      const bank = banks[i]
      const isCAN = bank.country === BankingCountry.CAN
      if (isCAN && bank.institutionNum && !isInstitutionNumValid(bank.institutionNum)) {
        isValid.institutionNum = false
      }
      if (isCAN && bank.transitNum && !isTransitNumValid(bank.transitNum)) {
        isValid.transitNum = false
      }
      if (!isCAN && bank.achCode && !isAchCodeValid(bank.achCode)) {
        isValid.achCode = false
      }
      if (!isCAN && bank.swiftBicCode && !isSwiftBicCodeValid(bank.swiftBicCode)) {
        isValid.swiftBicCode = false
      }
    }
    for (let i = 0; i < newBanks.length; i++) {
      const bank = newBanks[i]
      const isCAN = bank.country === BankingCountry.CAN
      if (isCAN && bank.institutionNum && !isInstitutionNumValid(bank.institutionNum)) {
        isValid.institutionNum = false
      }
      if (isCAN && bank.transitNum && !isTransitNumValid(bank.transitNum)) {
        isValid.transitNum = false
      }
      if (!isCAN && bank.achCode && !isAchCodeValid(bank.achCode)) {
        isValid.achCode = false
      }
      if (!isCAN && bank.swiftBicCode && !isSwiftBicCodeValid(bank.swiftBicCode)) {
        isValid.swiftBicCode = false
      }
    }
    return isValid
  }

  const isMissingBlockchainDetails = (): boolean => {
    for (let i = 0; i < blockchains.length; i++) {
      const wallet = blockchains[i]
      if ((wallet.name || wallet.address) && (!wallet.name || !wallet.address)) {
        return true
      }
    }
    for (let i = 0; i < newBlockchains.length; i++) {
      const wallet = newBlockchains[i]
      if ((wallet.name || wallet.address) && (!wallet.name || !wallet.address)) {
        return true
      }
    }
    return false
  }

  const isValidBlockchainAddresses = (): boolean => {
    for (let i = 0; i < blockchains.length; i++) {
      const wallet = blockchains[i]
      if (!isBlockchainAddressValid(wallet.address, wallet.chain as ChainName)) {
        return false
      }
    }
    for (let i = 0; i < newBlockchains.length; i++) {
      const wallet = newBlockchains[i]
      if (wallet.address && !isBlockchainAddressValid(wallet.address, wallet.chain as ChainName)) {
        return false
      }
    }
    return true
  }

  const bankAndBlockchainEmpty = (): boolean => {
    return !banks.length && !newBanks.length && !blockchains.length && !newBlockchains.length
  }

  const validate = () => {
    const validateBankDetails = isValidBankDetails()
    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.addressLine1 ||
      !values.postalCode ||
      !values.countryCode ||
      !values.city ||
      !values.stateProvince
    ) {
      setErrors({
        ...noErrors,
        firstName: !values.firstName,
        lastName: !values.lastName,
        email: !values.email,
        addressLine1: !values.addressLine1,
        postalCode: !values.postalCode,
        countryCode: !values.countryCode,
        city: !values.city,
        stateProvince: !values.stateProvince
      })
      throw new ValidationError('Please fill in required fields.')
    } else if (!isEmailValid(values.email)) {
      setErrors({
        ...noErrors,
        email: true
      })
      throw new ValidationError('Invalid email')
    } else if (!countryToAlpha2(values.countryCode)) {
      setErrors({
        ...noErrors,
        countryCode: true
      })
      throw new ValidationError('Invalid country')
    } else if (bankAndBlockchainEmpty()) {
      throw new ValidationError('A recipient must have a bank account or a blockchain address.')
    } else if (isMissingBankDetails()) {
      throw new ValidationError('Missing bank details')
    } else if (isMissingBillpayDetails()) {
      throw new ValidationError('Missing billpay details')
    } else if (isMissingBlockchainDetails()) {
      throw new ValidationError('Missing blockchain details')
    } else if (!validateBankDetails.institutionNum) {
      setErrors({
        ...noErrors,
        institutionNum: true
      })
      throw new ValidationError('Invalid institution num. Format: 3 digits')
    } else if (!validateBankDetails.transitNum) {
      setErrors({
        ...noErrors,
        transitNum: true
      })
      throw new ValidationError('Invalid transit num. Format: 5 digits')
    } else if (!validateBankDetails.achCode) {
      setErrors({
        ...noErrors,
        achCode: true
      })
      throw new ValidationError('Invalid ACH code. Format: 9 digits')
    } else if (!validateBankDetails.swiftBicCode) {
      setErrors({
        ...noErrors,
        swiftBicCode: true
      })
      throw new ValidationError('Invalid Swift BIC code. Format: min 8 digits, max 11 digits')
    } else if (!isValidBlockchainAddresses()) {
      setErrors({
        ...noErrors,
        address: true
      })
      throw new ValidationError('Invalid blockchain address')
    } else if (!isValidBillpayDetails()) {
      setErrors({
        ...noErrors,
        payeeCode: true,
        payeeAccountNumber: true
      })
      throw new ValidationError('Either payeeCode or payeeAccountNumber is not a number')
    }
  }

  const handleRequest = async () => {
    try {
      validate()
      if (newBanks.length) {
        const addNewBankResponse = await updateContactNewBankDetails({
          contactId: values.id,
          bankDetails: newBanks.map((b) => ({
            country: b.country,
            name: b.name,
            accHolderName: b.accHolderName,
            routingNum: `${b.institutionNum}-${b.transitNum}`,
            accountNum: b.accountNum,
            achCode: b.achCode,
            swiftBicCode: b.swiftBicCode
          }))
        })
        setBanks(
          banks.concat(
            addNewBankResponse.data.map((b) => ({
              id: b.id,
              country: b.country,
              name: b.name,
              institutionNum: b.routingNum.substring(0, 3),
              transitNum: b.routingNum.substring(4),
              routingNum: b.routingNum,
              accountNum: b.accountNum,
              accHolderName: b.accHolderName,
              isPrimary: b.isPrimary,
              achCode: b.achCode,
              swiftBicCode: b.swiftBicCode
            }))
          )
        )
        setNewBanks([])
      }
      if (newBlockchains.length) {
        const addNewBlockchainResponse = await updateContactNewBlockchainDetails({
          contactId: values.id,
          blockchainDetails: newBlockchains
        })
        setBlockchains(blockchains.concat(addNewBlockchainResponse.data))
        setNewBlockchains([])
      }
      const response = await updateContact({
        id: values.id,
        firstName: values.firstName,
        middleName: values.middleName ? values.middleName : '',
        lastName: values.lastName,
        email: values.email,
        companyTitle: values.companyTitle,
        address: values.addressLine2 ? `${values.addressLine1}, ${values.addressLine2}` : values.addressLine1,
        postalCode: values.postalCode,
        countryCode: values.countryCode,
        city: values.city,
        stateProvince: values.stateProvince,
        updateBankDetails: banks.map((bank) => ({
          id: bank.id,
          country: bank.country,
          name: bank.name,
          accHolderName: bank.accHolderName,
          routingNum: `${bank.institutionNum}-${bank.transitNum}`,
          accountNum: bank.accountNum,
          achCode: bank.achCode,
          swiftBicCode: bank.swiftBicCode
        })),
        updateBlockchainDetails: blockchains.map((wallet) => ({
          id: wallet.id,
          name: wallet.name,
          address: wallet.address,
          chain: wallet.chain
        })),
        updateBillPayDetails: isBillPayEnabled
          ? updatedBillpays.map((billpay) => ({
              id: billpay.id,
              payeeName: billpay.payeeName,
              payeeCode: billpay.payeeCode,
              payeeAccountNumber: billpay.payeeAccountNumber
            }))
          : []
      })
      if (isBillPayEnabled && newBillpays.length) {
        const addNewBillpayResponse = await updateContactNewBillPayDetails({
          contactId: values.id,
          billPayDetails: newBillpays
        })
        setBillpays(billpays.concat(addNewBillpayResponse.data))
        setNewBillpays([])
      }
      setErrors(noErrors)
      setIsEdit(false)
      if (response) {
        alertNotification('Changes have been saved to your address book', 'success')
      }
    } catch (e) {
      if (e instanceof ValidationError) {
        alertNotification(e.message, 'error')
      } else {
        alertNotification('Something went wrong, please try again later.', 'error')
      }
    }
  }

  const handleEdit = async () => {
    if (isEdit) {
      await handleRequest()
    } else {
      setIsEdit(true)
    }
  }

  const addNewBankDetails = () => {
    setNewBanks([
      ...newBanks,
      {
        country: BankingCountry.CAN,
        name: '',
        accHolderName: '',
        institutionNum: '',
        transitNum: '',
        routingNum: '',
        accountNum: '',
        achCode: '',
        swiftBicCode: ''
      }
    ])
    setIsEdit(true)
  }

  const deleteBankDetails = async (isNew: boolean, idx: number) => {
    try {
      if (isNew) {
        setNewBanks(newBanks.filter((i) => newBanks.indexOf(i) !== idx))
      } else {
        await deleteContactBankDetails({
          contactId: values.id,
          bankingDetailsId: `${banks[idx].id}`
        })
        setBanks(banks.filter((i) => banks.indexOf(i) !== idx))
        alertNotification('Bank details successfully deleted', 'success')
      }
    } catch {
      alertNotification('Error deleting bank details', 'error')
    }
  }

  const addNewBlockchainDetails = () => {
    setNewBlockchains([
      ...newBlockchains,
      {
        name: '',
        address: '',
        chain: ChainName.ETHEREUM
      }
    ])
    setIsEdit(true)
  }

  const deleteBlockchainDetails = async (isNew: boolean, idx: number) => {
    try {
      if (isNew) {
        setNewBlockchains(newBlockchains.filter((i) => newBlockchains.indexOf(i) !== idx))
      } else {
        await deleteContactBlockchainDetails({
          contactId: values.id,
          blockchainDetailsId: `${blockchains[idx].id}`
        })
        setBlockchains(blockchains.filter((i) => blockchains.indexOf(i) !== idx))
        alertNotification('Blockchain details successfully deleted', 'success')
      }
    } catch {
      alertNotification('Error deleting blockchain details', 'error')
    }
  }

  const addNewBillpayDetails = () => {
    setNewBillpays([
      ...newBillpays,
      {
        payeeName: '',
        payeeCode: '',
        payeeAccountNumber: ''
      }
    ])
    setIsEdit(true)
  }

  const deleteBillpayDetails = async (isNew: boolean, idx: number) => {
    try {
      if (isNew) {
        setNewBillpays(newBillpays.filter((i) => newBillpays.indexOf(i) !== idx))
      } else {
        await deleteContactBillpayDetails({
          contactId: values.id,
          billPayDetailsId: `${billpays[idx].id}`
        })
        setBillpays(billpays.filter((i) => billpays.indexOf(i) !== idx))
        alertNotification('Billpay details successfully deleted', 'success')
      }
    } catch {
      alertNotification('Error deleting billpay details', 'error')
    }
  }

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    alertNotification('Wallet address successfully copied!', 'success')
  }

  const openQR = (idx: number) => {
    setBlockchainIdx(idx)
    setShowQR(true)
  }

  const closeQR = () => {
    setShowQR(false)
  }

  const chainIcon = (chain: ChainName) => {
    if (chain === ChainName.ETHEREUM) {
      return <EthIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.ALGORAND) {
      return <AlgoIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.STELLAR) {
      return <StellarIcon sx={{ mr: 1 }} />
    }
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="subtitle2" color="grey.500" mb={2}>
          Please confirm the following beneficiary information is accurate.
        </Typography>
        <S.StyledButton
          variant="outlined"
          startIcon={isEdit ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
          onClick={handleEdit}
        >
          {isEdit ? 'Save changes' : 'Edit details'}
        </S.StyledButton>
      </Stack>
      <Divider sx={{ marginTop: '24px' }} />
      <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        <Box sx={{ width: '600px' }}>
          <Box sx={{ width: '400px' }}>
            <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
              Beneficiary Information
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            <SummaryTable
              data={[
                {
                  key: <Typography fontWeight={500}>First Name</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      error={errors.firstName}
                    />
                  ) : (
                    <Typography>{values.firstName}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Middle Name</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="middleName"
                      value={values.middleName}
                      onChange={handleChange}
                      error={errors.middleName}
                    />
                  ) : (
                    <Typography>{values.middleName}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Last Name</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      error={errors.lastName}
                    />
                  ) : (
                    <Typography>{values.lastName}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Email</Typography>,
                  value: isEdit ? (
                    <TextField name="email" value={values.email} onChange={handleChange} error={errors.email} />
                  ) : (
                    <Typography>{values.email}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Company Name</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="companyTitle"
                      value={values.companyTitle}
                      onChange={handleChange}
                      error={errors.companyTitle}
                    />
                  ) : (
                    <Typography>{values.companyTitle}</Typography>
                  )
                }
              ]}
              padding="16px 0"
            />
            <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
              Beneficiary Address
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            <SummaryTable
              data={[
                {
                  key: <Typography fontWeight={500}>Address Line 1</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="addressLine1"
                      value={values.addressLine1}
                      onChange={handleChange}
                      error={errors.addressLine1}
                    />
                  ) : (
                    <Typography>{values.addressLine1}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Address Line 2</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="addressLine2"
                      value={values.addressLine2}
                      onChange={handleChange}
                      error={errors.addressLine2}
                    />
                  ) : (
                    <Typography>{values.addressLine2}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Postal/Zip Code</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      error={errors.postalCode}
                    />
                  ) : (
                    <Typography>{values.postalCode}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>Country</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      error={errors.countryCode}
                    />
                  ) : (
                    <Typography>{values.countryCode}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>City</Typography>,
                  value: isEdit ? (
                    <TextField name="city" value={values.city} onChange={handleChange} error={errors.city} />
                  ) : (
                    <Typography>{values.city}</Typography>
                  )
                },
                {
                  key: <Typography fontWeight={500}>State/Province</Typography>,
                  value: isEdit ? (
                    <TextField
                      name="stateProvince"
                      value={values.stateProvince}
                      onChange={handleChange}
                      error={errors.stateProvince}
                    />
                  ) : (
                    <Typography>{values.stateProvince}</Typography>
                  )
                }
              ]}
              padding="16px 0"
            />
          </Box>
        </Box>
        <Box sx={{ width: '600px' }}>
          <Box sx={{ width: '400px' }}>
            <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
              Banking Details
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            {banks.map((bank, idx) => (
              <Box key={bank.isPrimary ? idx : bank.id}>
                <ListItem sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    {bank.country === BankingCountry.CAN ? (
                      <FlagIcon fontSize="small" />
                    ) : (
                      <USFlagIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {bank.name} ****{bank.accountNum.substring(bank.accountNum.length - 4)}{' '}
                    {bank.isPrimary && `(Primary)`}
                  </ListItemText>
                  {!bank.isPrimary && isEdit && (
                    <S.StyledButton2
                      startIcon={<BankIcon />}
                      variant="outlined"
                      onClick={() => handleSetAsPrimary(idx)}
                    >
                      Set as Primary
                    </S.StyledButton2>
                  )}
                  {isEdit && (
                    <S.StyledButton2
                      startIcon={<DeleteContactIcon />}
                      variant="outlined"
                      color="error"
                      sx={{ color: '#D3080C', ml: 1 }}
                      onClick={() => deleteBankDetails(false, idx)}
                    >
                      Delete Bank
                    </S.StyledButton2>
                  )}
                </ListItem>
                {isEdit && (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Country</Typography>,
                        value: (
                          <Button
                            startIcon={
                              bank.country === BankingCountry.CAN ? (
                                <FlagIcon fontSize="small" />
                              ) : (
                                <USFlagIcon fontSize="small" />
                              )
                            }
                            endIcon={<ExpandMore />}
                            color="primary"
                            onClick={(e) => handleOpenCountryMenu(e, false, idx)}
                            size="small"
                          >
                            {bank.country}
                          </Button>
                        )
                      }
                    ]}
                  />
                )}
                {bank.country === BankingCountry.CAN ? (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.accHolderName}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.bankName}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Institution Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="institutionNum"
                            value={bank.institutionNum}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.institutionNum}
                          />
                        ) : (
                          <Typography>{bank.institutionNum}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Transit Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="transitNum"
                            value={bank.transitNum}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.transitNum}
                          />
                        ) : (
                          <Typography>{bank.transitNum}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.accountNum}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                    padding="16px 0"
                  />
                ) : (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.accHolderName}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.bankName}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>ACH Code</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="achCode"
                            value={bank.achCode}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.achCode}
                          />
                        ) : (
                          <Typography>{bank.achCode}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>SWIFT/BIC Code</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="swiftBicCode"
                            value={bank.swiftBicCode}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.swiftBicCode}
                          />
                        ) : (
                          <Typography>{bank.swiftBicCode}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleBankDetails}
                            onSelect={() => setBankIdx(idx)}
                            error={errors.accountNum}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                    padding="16px 0"
                  />
                )}
              </Box>
            ))}
            {newBanks.map((bank, idx) => (
              <Box key={idx}>
                <ListItem sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    {bank.country === BankingCountry.CAN ? (
                      <FlagIcon fontSize="small" />
                    ) : (
                      <USFlagIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {bank.name} ****{bank.accountNum.substring(bank.accountNum.length - 4)}
                  </ListItemText>
                  {isEdit && (
                    <S.StyledButton
                      startIcon={<DeleteContactIcon />}
                      variant="outlined"
                      color="error"
                      sx={{ color: '#D3080C', border: 0 }}
                      onClick={() => deleteBankDetails(true, idx)}
                    >
                      Delete Bank
                    </S.StyledButton>
                  )}
                </ListItem>
                {isEdit && (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Country</Typography>,
                        value: (
                          <Button
                            startIcon={
                              bank.country === BankingCountry.CAN ? (
                                <FlagIcon fontSize="small" />
                              ) : (
                                <USFlagIcon fontSize="small" />
                              )
                            }
                            endIcon={<ExpandMore />}
                            color="primary"
                            onClick={(e) => handleOpenCountryMenu(e, true, idx)}
                            size="small"
                          >
                            {bank.country}
                          </Button>
                        )
                      }
                    ]}
                  />
                )}
                {bank.country === BankingCountry.CAN ? (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.accHolderName}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.bankName}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Institution Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="institutionNum"
                            value={bank.institutionNum}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.institutionNum}
                          />
                        ) : (
                          <Typography>{bank.institutionNum}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Transit Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="transitNum"
                            value={bank.transitNum}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.transitNum}
                          />
                        ) : (
                          <Typography>{bank.transitNum}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.accountNum}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                    padding="16px 0"
                  />
                ) : (
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.accHolderName}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.bankName}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>ACH Code</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="achCode"
                            value={bank.achCode}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.achCode}
                          />
                        ) : (
                          <Typography>{bank.achCode}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>SWIFT/BIC Code</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="swiftBicCode"
                            value={bank.swiftBicCode}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.swiftBicCode}
                          />
                        ) : (
                          <Typography>{bank.swiftBicCode}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleNewBankDetails}
                            onSelect={() => setNewBankIdx(idx)}
                            error={errors.accountNum}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                    padding="16px 0"
                  />
                )}
              </Box>
            ))}
            <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
              <S.StyledButton variant="outlined" startIcon={<AddBankIcon />} onClick={addNewBankDetails}>
                Add another Bank Account
              </S.StyledButton>
            </Box>
            <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
              Blockchain Address
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            {blockchains.map((wallet, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                {chainIcon(wallet.chain as ChainName)}
                <Typography sx={{ width: '120px' }}>{wallet.name}</Typography>
                <Link
                  component="a"
                  href={
                    wallet.chain != ChainName.STELLAR
                      ? `${EXPLORER[wallet.chain as ChainName]}/address/${wallet.address}`
                      : `${EXPLORER[wallet.chain as ChainName]}/account/${wallet.address}`
                  }
                  target="_blank"
                  sx={{ ml: 3, mr: 1 }}
                >
                  {trimId(wallet.address)}
                </Link>
                <IconButton aria-label="copy" onClick={() => copyToClipboard(wallet.address)}>
                  <ContentCopyIcon />
                </IconButton>
                <IconButton aria-label="showQR" onClick={() => openQR(idx)}>
                  <QRCodeIcon />
                </IconButton>
              </Box>
            ))}
            {newBlockchains.map((wallet, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                {chainIcon(wallet.chain as ChainName)}
                <Typography sx={{ width: '120px' }}>{wallet.name}</Typography>
                <Link
                  component="a"
                  href={`${EXPLORER[wallet.chain as ChainName]}/address/${wallet.address}`}
                  target="_blank"
                  sx={{ ml: 3, mr: 1 }}
                >
                  {trimId(wallet.address)}
                </Link>
                <IconButton aria-label="copy" onClick={() => copyToClipboard(wallet.address)}>
                  <ContentCopyIcon />
                </IconButton>
                <IconButton aria-label="showQR" onClick={() => openQR(idx)}>
                  <QRCodeIcon />
                </IconButton>
              </Box>
            ))}
            <S.StyledButton
              startIcon={<BlockchainIcon />}
              variant="outlined"
              sx={{ fontWeight: '500', mt: 2 }}
              onClick={() => setBlockchainOpen(true)}
            >
              View or Add New Blockchain Addresses
            </S.StyledButton>
            {isBillPayEnabled && (
              <Box>
                <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
                  Bill Pay Details
                </Typography>
                <Divider sx={{ marginBottom: '24px' }} />
                <Alert severity={'info'} sx={{ marginBottom: '12px' }}>
                  {' '}
                  Format: (Payee Code - Account Number)
                </Alert>
                {billpays.map((billpay, idx) => (
                  <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                    {<AccountBalanceIcon sx={{ mr: 1 }} />}
                    <Typography>{`${billpay.payeeName} (${billpay.payeeCode} - ${billpay.payeeAccountNumber})`}</Typography>
                  </Box>
                ))}
                {newBillpays.map((billpay, idx) => (
                  <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                    {<AccountBalanceIcon sx={{ mr: 1 }} />}
                    <Typography>{`${billpay.payeeName} (${billpay.payeeCode} - ${billpay.payeeAccountNumber})`}</Typography>
                  </Box>
                ))}
                <S.StyledButton
                  startIcon={<MonetizationOnIcon />}
                  variant="outlined"
                  sx={{ fontWeight: '500', mt: 2 }}
                  onClick={() => setBillpayOpen(true)}
                >
                  View or Add New Bill Pay Detail
                </S.StyledButton>
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
      <Modal
        open={blockchainOpen}
        onClose={() => setBlockchainOpen(false)}
        title="Beneficiary Blockchain Addresses"
        maxWidth="md"
        fullWidth
        keepMounted
      >
        <Typography variant="subtitle2" color="grey.500" mb={2}>
          Please confirm the following blockchain address information is accurate.
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <S.StyledButton variant="outlined" startIcon={<AddOutlinedIcon />} onClick={addNewBlockchainDetails}>
            Add New Blockchain Address
          </S.StyledButton>
          {!isEdit && (
            <S.StyledButton variant="outlined" startIcon={<EditOutlinedIcon />} onClick={() => setIsEdit(true)}>
              Edit details
            </S.StyledButton>
          )}
          {isEdit && (
            <S.StyledButton
              variant="outlined"
              startIcon={<DoneOutlinedIcon />}
              onClick={() => setBlockchainOpen(false)}
            >
              Done
            </S.StyledButton>
          )}
        </Stack>
        <Divider sx={{ marginBottom: '24px' }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address Nickname</TableCell>
                <TableCell>Blockchain Address</TableCell>
                <TableCell>Blockchain Network</TableCell>
                <TableCell>Supported Assets</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {blockchains.map((wallet, idx) => (
              <TableBody key={wallet.id}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {isEdit ? (
                      <TextField
                        name="name"
                        value={wallet.name}
                        onChange={handleBlockchainDetails}
                        onSelect={() => setBlockchainIdx(idx)}
                        error={errors.name}
                      />
                    ) : (
                      <Typography>{wallet.name}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="address"
                        value={wallet.address}
                        onChange={handleBlockchainDetails}
                        onSelect={() => setBlockchainIdx(idx)}
                        error={errors.address}
                      />
                    ) : (
                      <Typography>{trimId(wallet.address)}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <Button
                        endIcon={<ExpandMore />}
                        color="primary"
                        onClick={(e) => handleOpenChainMenu(e, idx)}
                        size="small"
                      >
                        {wallet.chain}
                      </Button>
                    ) : (
                      <Typography>{wallet.chain}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <QCADIcon fontSize="small" /> <USDCIcon fontSize="small" />
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => deleteBlockchainDetails(false, idx)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
            {newBlockchains.map((wallet, idx) => (
              <TableBody key={idx}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {isEdit ? (
                      <TextField
                        name="name"
                        value={wallet.name}
                        onChange={handleNewBlockchainDetails}
                        onSelect={() => setNewBlockchainIdx(idx)}
                        error={errors.name}
                      />
                    ) : (
                      <Typography>{wallet.name}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="address"
                        value={wallet.address}
                        onChange={handleNewBlockchainDetails}
                        onSelect={() => setNewBlockchainIdx(idx)}
                        error={errors.address}
                      />
                    ) : (
                      <Typography>{trimId(wallet.address)}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <Button
                        endIcon={<ExpandMore />}
                        color="primary"
                        onClick={(e) => handleOpenChainMenu(e, idx)}
                        size="small"
                      >
                        {wallet.chain}
                      </Button>
                    ) : (
                      <Typography>{wallet.chain}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <QCADIcon fontSize="small" /> <USDCIcon fontSize="small" />
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => deleteBlockchainDetails(true, idx)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Modal>
      {blockchains[blockchainIdx] && (
        <Modal open={showQR} onClose={closeQR} title={`${blockchains[blockchainIdx].name} address`} keepMounted>
          <Divider />
          <Box display="flex" alignItems="center" flexDirection="column">
            {blockchains[blockchainIdx].address && (
              <S.CustomQRCode value={blockchains[blockchainIdx].address} size={200} />
            )}
            <Typography>{blockchains[blockchainIdx].address}</Typography>
          </Box>
        </Modal>
      )}

      <Modal
        open={billpayOpen}
        onClose={() => setBillpayOpen(false)}
        title="Beneficiary Bill Pay Details"
        maxWidth="md"
        fullWidth
        keepMounted
      >
        <Typography variant="subtitle2" color="grey.500" mb={2}>
          Please confirm the following bill pay details information are accurate.
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <S.StyledButton variant="outlined" startIcon={<AddOutlinedIcon />} onClick={addNewBillpayDetails}>
            Add New Bill Pay Details
          </S.StyledButton>
          {!isEdit && (
            <S.StyledButton variant="outlined" startIcon={<EditOutlinedIcon />} onClick={() => setIsEdit(true)}>
              Edit details
            </S.StyledButton>
          )}
          {isEdit && (
            <S.StyledButton variant="outlined" startIcon={<DoneOutlinedIcon />} onClick={() => setBillpayOpen(false)}>
              Done
            </S.StyledButton>
          )}
        </Stack>
        <Divider sx={{ marginBottom: '24px' }} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payee Name</TableCell>
                <TableCell>Payee Code</TableCell>
                <TableCell>Payee Account Number</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {billpays.map((billpay, idx) => (
              <TableBody key={billpay.id}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {isEdit ? (
                      <TextField
                        name="payeeName"
                        value={billpay.payeeName}
                        onChange={handleBillpayDetails}
                        onSelect={() => setBillpayIdx(idx)}
                        error={errors.payeeName}
                      />
                    ) : (
                      <Typography>{billpay.payeeName}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="payeeCode"
                        value={billpay.payeeCode}
                        onChange={handleBillpayDetails}
                        onSelect={() => setBillpayIdx(idx)}
                        error={errors.payeeCode}
                      />
                    ) : (
                      <Typography>{billpay.payeeCode}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="payeeAccountNumber"
                        value={billpay.payeeAccountNumber}
                        onChange={handleBillpayDetails}
                        onSelect={() => setBillpayIdx(idx)}
                        error={errors.payeeAccountNumber}
                      />
                    ) : (
                      <Typography>{billpay.payeeAccountNumber}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => deleteBillpayDetails(false, idx)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
            {newBillpays.map((billpay, idx) => (
              <TableBody key={idx}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="payeeName"
                        value={billpay.payeeName}
                        onChange={handleNewBillpayDetails}
                        onSelect={() => setNewBillpayIdx(idx)}
                        error={errors.payeeName}
                      />
                    ) : (
                      <Typography>{billpay.payeeName}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="payeeCode"
                        value={billpay.payeeCode}
                        onChange={handleNewBillpayDetails}
                        onSelect={() => setNewBillpayIdx(idx)}
                        error={errors.payeeCode}
                      />
                    ) : (
                      <Typography>{billpay.payeeCode}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEdit ? (
                      <TextField
                        name="payeeAccountNumber"
                        value={billpay.payeeAccountNumber}
                        onChange={handleNewBillpayDetails}
                        onSelect={() => setNewBillpayIdx(idx)}
                        error={errors.payeeAccountNumber}
                      />
                    ) : (
                      <Typography>{billpay.payeeAccountNumber}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => deleteBillpayDetails(true, idx)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Modal>
      <Menu
        sx={{ mt: '7px' }}
        id="menu-country"
        anchorEl={anchorElCountry}
        keepMounted
        open={Boolean(anchorElCountry)}
        onClose={handleCloseCountryMenu}
      >
        {Object.values(BankingCountry).map((country) => (
          <MenuItem key={country} onClick={() => chooseCountry(country)}>
            <Typography>{country}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        sx={{ mt: '7px' }}
        id="menu-chain"
        anchorEl={anchorElChain}
        keepMounted
        open={Boolean(anchorElChain)}
        onClose={handleCloseChainMenu}
      >
        {Object.values(ChainName).map((chain) => (
          <MenuItem
            key={chain}
            onClick={() => chooseChain(chain)}
            disabled={chain === ChainName.ALGORAND || chain === ChainName.STELLAR}
          >
            <Typography>{chain}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Contact
