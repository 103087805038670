import { FormikHelpers } from 'formik'
import { useState } from 'react'
import { isEmailValid } from 'src/common/helpers'
import { apiSignUpDTO, createApiSignupRequest } from '../../common/api/onboardApi/index'

export interface State {
  error?: string
  success?: string
}

export const initialValues: apiSignUpDTO = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  blockchains: [],
  productGoals: ''
}

export const validate = (values: apiSignUpDTO) => {
  const errors: Partial<apiSignUpDTO> = {}
  if (!values.company.trim()) {
    errors.company = 'Required'
  }
  if (!values.firstName.trim()) {
    errors.firstName = 'Required'
  }
  if (!values.lastName.trim()) {
    errors.lastName = 'Required'
  }
  if (!values.email.trim()) {
    errors.email = 'Required'
  } else if (!isEmailValid(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.blockchains.length) {
    errors.blockchains = ['Please select at least one']
  }
  if (!values.productGoals.trim()) {
    errors.productGoals = 'Required'
  }
  return errors
}

const sendOnboardEmail = async (
  apiSignupParams: apiSignUpDTO,
  cb: (authSuccess: boolean, response: string) => void
) => {
  try {
    const response = await createApiSignupRequest(apiSignupParams)
    cb(true, response.data)
  } catch (error) {
    cb(false, (error as Error).message)
  }
}

const useApiOnboard = () => {
  const [state, setState] = useState<State>({
    error: undefined,
    success: undefined
  })

  const onSubmit = (values: apiSignUpDTO, { setSubmitting, resetForm }: FormikHelpers<apiSignUpDTO>) => {
    const { company, email, firstName, lastName, blockchains, productGoals } = values

    const signupParams = {
      company: company.trim(),
      email: email.trim(),
      blockchains,
      productGoals,
      firstName: firstName.trim(),
      lastName: lastName.trim()
    }

    sendOnboardEmail(signupParams, (isSuccess, response) => {
      if (isSuccess) {
        setState({
          ...state,
          success: response,
          error: undefined
        })
        resetForm()
        setSubmitting(false)
      } else {
        setState({
          ...state,
          error: response,
          success: undefined
        })
        setSubmitting(false)
      }
    })
  }

  return { state, setState, onSubmit }
}

export default useApiOnboard
