import { LoadingButton } from '@mui/lab'
import { Button, Divider, IconButton, Link, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { formatNumber, trimId } from 'src/common/helpers'
import { Modal, SummaryTable } from 'src/ui'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import * as S from './styles'
import { ApayloId, BankingCountry, ChainName, EXPLORER } from 'src/common/static'
import {
  AddBankIcon,
  AlgoIcon,
  EthIcon,
  FlagIcon,
  InteracIcon,
  QRCodeIcon,
  StellarIcon,
  USFlagIcon
} from 'src/common/assets'
import useProfile from './Profile.State'
import { ExpandMore } from '@mui/icons-material'
import { DeleteContactIcon } from 'src/common/assets/DeleteContactIcon'

const Profile: React.FC = () => {
  const {
    user,
    wallets,
    open,
    submitting,
    values,
    bankDetails,
    newBankDetails,
    errors,
    availableLimits,
    isEdit,
    blockchainIdx,
    anchorElCountry,
    showQR,
    setBankIdx,
    setNewBankIdx,
    sendIncreaseLimitRequest,
    handleOpenLimit,
    handleCloseLimit,
    handleOpenCountryMenu,
    handleCloseCountryMenu,
    chooseCountry,
    handleChange,
    handleChangeNewBank,
    handleAddNewBank,
    handleDeleteBank,
    handleEdit,
    copyToClipboard,
    openQR,
    closeQR
  } = useProfile()

  const chainIcon = (chain: ChainName) => {
    if (chain === ChainName.ETHEREUM) {
      return <EthIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.ALGORAND) {
      return <AlgoIcon sx={{ mr: 1 }} />
    } else if (chain === ChainName.STELLAR) {
      return <StellarIcon sx={{ mr: 1 }} />
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mt="24px">
        <S.StyledButton
          variant="outlined"
          startIcon={isEdit ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
          onClick={handleEdit}
        >
          {isEdit ? 'Save changes' : 'Edit details'}
        </S.StyledButton>
      </Box>
      <S.Flex mt="24px" mb="48px">
        <Box>
          <Box sx={{ width: '500px' }}>
            <Typography variant="subtitle2" color="grey.500">
              Profile
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            <SummaryTable
              data={[
                {
                  key: <Typography fontWeight={500}>First Name</Typography>,
                  value: <Typography>{values.firstName}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>Last Name</Typography>,
                  value: <Typography>{values.lastName}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>Company Name</Typography>,
                  value: <Typography>{user?.business_name}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>Email</Typography>,
                  value: <Typography>{user?.email}</Typography>
                }
              ]}
            />
            <Typography variant="subtitle2" color="grey.500" sx={{ marginTop: '24px' }}>
              Address
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            <SummaryTable
              data={[
                {
                  key: <Typography fontWeight={500}>Address</Typography>,
                  value: <Typography>{values.address}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>Postal/Zip Code</Typography>,
                  value: <Typography>{values.postalCode}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>Country</Typography>,
                  value: <Typography>{values.countryCode}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>City</Typography>,
                  value: <Typography>{values.city}</Typography>
                },
                {
                  key: <Typography fontWeight={500}>State/Province</Typography>,
                  value: <Typography>{values.stateProvince}</Typography>
                }
              ]}
            />
          </Box>
        </Box>
        <Box sx={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <S.TierChip variant="outlined" color="success" label="Level 1 verified" />
            <Button size="small" onClick={handleOpenLimit}>
              Increase limit
            </Button>
            <Typography mt="8px">
              <b>${formatNumber(availableLimits.daily)} CAD</b> available / day |{' '}
              <b>${formatNumber(availableLimits.weekly)} CAD</b> available / week
            </Typography>
          </Box>
          <Box sx={{ width: '500px', mt: '24px' }}>
            <Typography variant="subtitle2" color="grey.500">
              Banking Details
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            {!!bankDetails.length &&
              bankDetails.map((bank, idx) => (
                <Box key={idx} mb={2}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Box display="flex">
                      {bank.country === BankingCountry.CAN ? (
                        <FlagIcon fontSize="small" />
                      ) : (
                        <USFlagIcon fontSize="small" />
                      )}
                      <Typography fontWeight={500} ml={1}>
                        {bank.name} ****{bank.accountNum.substring(bank.accountNum.length - 4)}
                      </Typography>
                    </Box>
                    {isEdit && (
                      <S.StyledButton2
                        startIcon={<DeleteContactIcon />}
                        variant="outlined"
                        color="error"
                        sx={{ color: '#D3080C', ml: 1 }}
                        onClick={() => handleDeleteBank(false, idx)}
                      >
                        Delete Bank
                      </S.StyledButton2>
                    )}
                  </Box>
                  {isEdit && (
                    <SummaryTable
                      data={[
                        {
                          key: <Typography fontWeight={500}>Country</Typography>,
                          value: (
                            <Button
                              startIcon={
                                bank.country === BankingCountry.CAN ? (
                                  <FlagIcon fontSize="small" />
                                ) : (
                                  <USFlagIcon fontSize="small" />
                                )
                              }
                              endIcon={<ExpandMore />}
                              color="primary"
                              onClick={(e) => handleOpenCountryMenu(e, false, idx)}
                              size="small"
                            >
                              {bank.country}
                            </Button>
                          )
                        }
                      ]}
                    />
                  )}
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleChange}
                            error={errors.accHolderName}
                            onSelect={() => setBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleChange}
                            error={errors.name}
                            onSelect={() => setBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: (
                          <Typography fontWeight={500}>
                            {bank.country === BankingCountry.CAN ? 'Institution Number' : 'ACH Code'}
                          </Typography>
                        ),
                        value: isEdit ? (
                          <TextField
                            name={bank.country === BankingCountry.CAN ? 'institutionNum' : 'achCode'}
                            value={bank.country === BankingCountry.CAN ? bank.institutionNum : bank.achCode}
                            onChange={handleChange}
                            error={bank.country === BankingCountry.CAN ? errors.institutionNum : errors.achCode}
                            onSelect={() => setBankIdx(idx)}
                          />
                        ) : (
                          <Typography>
                            {bank.country === BankingCountry.CAN ? bank.institutionNum : bank.achCode}
                          </Typography>
                        )
                      },
                      {
                        key: (
                          <Typography fontWeight={500}>
                            {bank.country === BankingCountry.CAN ? 'Transit Number' : 'SWIFT/BIC Code'}
                          </Typography>
                        ),
                        value: isEdit ? (
                          <TextField
                            name={bank.country === BankingCountry.CAN ? 'transitNum' : 'swiftBicCode'}
                            value={bank.country === BankingCountry.CAN ? bank.transitNum : bank.swiftBicCode}
                            onChange={handleChange}
                            error={bank.country === BankingCountry.CAN ? errors.transitNum : errors.swiftBicCode}
                            onSelect={() => setBankIdx(idx)}
                          />
                        ) : (
                          <Typography>
                            {bank.country === BankingCountry.CAN ? bank.transitNum : bank.swiftBicCode}
                          </Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleChange}
                            error={errors.accountNum}
                            onSelect={() => setBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                  />
                </Box>
              ))}
            {!!newBankDetails.length &&
              newBankDetails.map((bank, idx) => (
                <Box key={idx} mb={2}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                    <Box display="flex">
                      {bank.country === BankingCountry.CAN ? (
                        <FlagIcon fontSize="small" />
                      ) : (
                        <USFlagIcon fontSize="small" />
                      )}
                      <Typography fontWeight={500} ml={1}>
                        {bank.name} ****{bank.accountNum.substring(bank.accountNum.length - 4)}
                      </Typography>
                    </Box>
                    {isEdit && (
                      <S.StyledButton2
                        startIcon={<DeleteContactIcon />}
                        variant="outlined"
                        color="error"
                        sx={{ color: '#D3080C', ml: 1 }}
                        onClick={() => handleDeleteBank(true, idx)}
                      >
                        Delete Bank
                      </S.StyledButton2>
                    )}
                  </Box>
                  {isEdit && (
                    <SummaryTable
                      data={[
                        {
                          key: <Typography fontWeight={500}>Country</Typography>,
                          value: (
                            <Button
                              startIcon={
                                bank.country === BankingCountry.CAN ? (
                                  <FlagIcon fontSize="small" />
                                ) : (
                                  <USFlagIcon fontSize="small" />
                                )
                              }
                              endIcon={<ExpandMore />}
                              color="primary"
                              onClick={(e) => handleOpenCountryMenu(e, true, idx)}
                              size="small"
                            >
                              {bank.country}
                            </Button>
                          )
                        }
                      ]}
                    />
                  )}
                  <SummaryTable
                    data={[
                      {
                        key: <Typography fontWeight={500}>Account Holder Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accHolderName"
                            value={bank.accHolderName}
                            onChange={handleChangeNewBank}
                            error={errors.accHolderName}
                            onSelect={() => setNewBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.accHolderName}</Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Bank Name</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="name"
                            value={bank.name}
                            onChange={handleChangeNewBank}
                            error={errors.name}
                            onSelect={() => setNewBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.name}</Typography>
                        )
                      },
                      {
                        key: (
                          <Typography fontWeight={500}>
                            {bank.country === BankingCountry.CAN ? 'Institution Number' : 'ACH Code'}
                          </Typography>
                        ),
                        value: isEdit ? (
                          <TextField
                            name={bank.country === BankingCountry.CAN ? 'institutionNum' : 'achCode'}
                            value={bank.country === BankingCountry.CAN ? bank.institutionNum : bank.achCode}
                            onChange={handleChangeNewBank}
                            error={bank.country === BankingCountry.CAN ? errors.institutionNum : errors.achCode}
                            onSelect={() => setNewBankIdx(idx)}
                          />
                        ) : (
                          <Typography>
                            {bank.country === BankingCountry.CAN ? bank.institutionNum : bank.achCode}
                          </Typography>
                        )
                      },
                      {
                        key: (
                          <Typography fontWeight={500}>
                            {bank.country === BankingCountry.CAN ? 'Transit Number' : 'SWIFT/BIC Code'}
                          </Typography>
                        ),
                        value: isEdit ? (
                          <TextField
                            name={bank.country === BankingCountry.CAN ? 'transitNum' : 'swiftBicCode'}
                            value={bank.country === BankingCountry.CAN ? bank.transitNum : bank.swiftBicCode}
                            onChange={handleChangeNewBank}
                            error={bank.country === BankingCountry.CAN ? errors.transitNum : errors.swiftBicCode}
                            onSelect={() => setNewBankIdx(idx)}
                          />
                        ) : (
                          <Typography>
                            {bank.country === BankingCountry.CAN ? bank.transitNum : bank.swiftBicCode}
                          </Typography>
                        )
                      },
                      {
                        key: <Typography fontWeight={500}>Account Number</Typography>,
                        value: isEdit ? (
                          <TextField
                            name="accountNum"
                            value={bank.accountNum}
                            onChange={handleChangeNewBank}
                            error={errors.accountNum}
                            onSelect={() => setNewBankIdx(idx)}
                          />
                        ) : (
                          <Typography>{bank.accountNum}</Typography>
                        )
                      }
                    ]}
                  />
                </Box>
              ))}
            <S.StyledButton variant="outlined" startIcon={<AddBankIcon />} onClick={handleAddNewBank}>
              Add a Bank Account
            </S.StyledButton>
          </Box>
          <Box sx={{ width: '500px', mt: '24px' }}>
            <Typography variant="subtitle2" color="grey.500">
              Interac Details
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            {!values.interacEmail && !isEdit && (
              <S.StyledButton variant="outlined" startIcon={<InteracIcon />} onClick={handleEdit}>
                Add Interac Details
              </S.StyledButton>
            )}
            {(values.interacEmail || isEdit) && (
              <SummaryTable
                data={[
                  {
                    key: (
                      <Typography fontWeight={500} display="flex" alignItems="center">
                        <InteracIcon sx={{ mr: 1 }} />
                        Interac e-Transfer Email
                      </Typography>
                    ),
                    value: isEdit ? (
                      <TextField
                        name="interacEmail"
                        value={values.interacEmail}
                        onChange={handleChange}
                        error={errors.interacEmail}
                      />
                    ) : (
                      <Typography>{values.interacEmail}</Typography>
                    )
                  },
                  {
                    key: <Typography fontWeight={500}>e-Transfer Security Answer</Typography>,
                    value: (
                      <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <Typography>
                          {ApayloId.PREFIX}
                          {values.accountId}
                        </Typography>
                        <IconButton
                          aria-label="copy"
                          onClick={() => copyToClipboard(`${ApayloId.PREFIX}${values.accountId}`)}
                          sx={{ mr: -1 }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Box>
                    )
                  }
                ]}
              />
            )}
          </Box>
          <Box sx={{ width: '500px', mt: '24px' }}>
            <Typography variant="subtitle2" color="grey.500">
              Blockchain Addresses
            </Typography>
            <Divider sx={{ marginBottom: '24px' }} />
            {wallets.map((wallet, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
                {chainIcon(wallet.chain as ChainName)}
                <Typography sx={{ width: '120px' }}>{wallet.name}</Typography>
                <Link
                  component="a"
                  href={
                    wallet.chain != ChainName.STELLAR
                      ? `${EXPLORER[wallet.chain as ChainName]}/address/${wallet.address}`
                      : `${EXPLORER[wallet.chain as ChainName]}/account/${wallet.address}`
                  }
                  target="_blank"
                  sx={{ ml: 3, mr: 1 }}
                >
                  {trimId(wallet.address)}
                </Link>
                <IconButton aria-label="copy" onClick={() => copyToClipboard(wallet.address)}>
                  <ContentCopyIcon />
                </IconButton>
                <IconButton aria-label="showQR" onClick={() => openQR(idx)}>
                  <QRCodeIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </S.Flex>
      {user?.business_name && (
        <S.Flex>
          <Box>
            <Typography color="grey.700">Business Name</Typography>
            <Typography variant="h5" fontWeight={600} mb="8px">
              {user?.business_name}
            </Typography>
          </Box>
        </S.Flex>
      )}
      <Modal open={open} onClose={handleCloseLimit} title="Increase your limit">
        <Typography mb={2}>
          Are you sure you want to increase your limit? We will send a request on your behalf for the reviewers to
          review your eligibility.
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={handleCloseLimit}>Close</Button>
          <LoadingButton variant="contained" onClick={sendIncreaseLimitRequest} loading={submitting}>
            Send
          </LoadingButton>
        </Stack>
      </Modal>
      {wallets[blockchainIdx] && (
        <Modal open={showQR} onClose={closeQR} title={`${wallets[blockchainIdx].name} address`} keepMounted>
          <Divider />
          <Box display="flex" alignItems="center" flexDirection="column">
            {wallets[blockchainIdx].address && <S.CustomQRCode value={wallets[blockchainIdx].address} size={200} />}
            <Typography>{wallets[blockchainIdx].address}</Typography>
          </Box>
        </Modal>
      )}
      <Menu
        sx={{ mt: '7px' }}
        id="menu-country"
        anchorEl={anchorElCountry}
        keepMounted
        open={Boolean(anchorElCountry)}
        onClose={handleCloseCountryMenu}
      >
        {Object.values(BankingCountry).map((country) => (
          <MenuItem key={country} onClick={() => chooseCountry(country)}>
            <Typography>{country}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Profile
