import React from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AlertDialog, AlertNotification, theme } from 'src/ui'
import {
  Signup,
  Login,
  LoginMfa,
  Navbar,
  Dashboard,
  PageNotFound,
  UserManagement,
  Profile,
  Wallets,
  Security,
  Orders,
  ResetPassword,
  ConfirmEmailVerification,
  Purchase,
  Redeem,
  PaymentMethods,
  SendToBlockchainWallet,
  Send,
  AddFunds,
  RedeemThirdParty,
  AddFundsFromBlockchainWallet,
  AddressBook,
  ApiOnboard
} from 'src/pages'
import ScrollToTop from './ScrollToTop'
import RequireAuth from './RequireAuth'
import { AppContextProvider } from 'src/common/context'
import { CustomRouter } from './CustomRouter'
import { history } from './history'
import { Box } from '@mui/system'

const AppRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertDialog />
      <AlertNotification />
      <AppContextProvider>
        <CustomRouter history={history}>
          <ScrollToTop />
          <Routes>
            <Route
              element={
                <Box flex="1 0 auto">
                  <Navbar />
                  <Outlet />
                </Box>
              }
            >
              <Route
                path="/mfa"
                element={
                  <RequireAuth authType="guest">
                    <LoginMfa />
                  </RequireAuth>
                }
              />
              <Route
                index
                element={
                  <RequireAuth authType="protected">
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/send"
                element={
                  <RequireAuth authType="protected">
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route index element={<Send />} />
                <Route path="fiat-to-my-bank-account" element={<Redeem />} />
                <Route path="digital-assets-to-blockchain-wallet" element={<SendToBlockchainWallet />} />
                <Route path="fiat-to-third-party-bank-account" element={<RedeemThirdParty />} />
              </Route>
              <Route
                path="/add-funds"
                element={
                  <RequireAuth authType="protected">
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route index element={<AddFunds />} />
                <Route path="digital-assets-to-my-wallet" element={<Purchase />} />
                <Route
                  path="transfer-digital-assets-from-blockchain-wallet"
                  element={<AddFundsFromBlockchainWallet />}
                />
              </Route>
              <Route
                path="/address-book"
                element={
                  <RequireAuth authType="protected">
                    <AddressBook />
                  </RequireAuth>
                }
              />
              {/* <Route
                path="/send"
                element={
                  <RequireAuth authType="protected">
                    <SendToBlockchainWallet />
                  </RequireAuth>
                }
              /> */}
              <Route
                path="/orders"
                element={
                  <RequireAuth authType="protected">
                    <Orders />
                  </RequireAuth>
                }
              />
              <Route
                path="/signup"
                element={
                  <RequireAuth authType="guest">
                    <Signup />
                  </RequireAuth>
                }
              />
              <Route
                path="/signup/api"
                element={
                  <RequireAuth authType="guest">
                    <ApiOnboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/login"
                element={
                  <RequireAuth authType="guest">
                    <Login />
                  </RequireAuth>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <RequireAuth authType="guest">
                    <ResetPassword />
                  </RequireAuth>
                }
              />
              <Route
                path="/email-verification"
                element={
                  <RequireAuth authType="guest">
                    <ConfirmEmailVerification />
                  </RequireAuth>
                }
              />
              <Route
                element={
                  <RequireAuth authType="protected">
                    <UserManagement />
                  </RequireAuth>
                }
              >
                <Route path="/profile" element={<Profile />} />
                <Route path="/wallets" element={<Wallets />} />
                <Route path="/payment" element={<PaymentMethods />} />
                <Route path="/security" element={<Security />} />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </CustomRouter>
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default AppRoutes
