import { useMetaMask } from 'metamask-react'
import { useState, useEffect } from 'react'
import { useWallet } from 'src/common/context'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { ChainAsset, ChainName, WalletType } from 'src/common/static'
import { alertNotification } from 'src/ui'
import { USDCcontractABI, QCADcontractABI } from 'src/common/AssetContracts'
import { ContactDetailsModel, getContacts } from 'src/common/api/contacts'

interface Errors {
  inputError?: string
  walletError?: string
}

const useNonCustodial = () => {
  const { selectedWallet, wallets, setIsSignMetamaskOpen } = useWallet()

  const { ethereum } = useMetaMask()
  const [value, setValue] = useState('')
  const [asset, setAsset] = useState(ChainAsset.USDC)
  const [recipient, setRecipient] = useState<string>('')
  const [recipientList, setRecipientList] = useState<ContactDetailsModel[]>([])
  const [search, setSearch] = useState('')
  const [filteredList, setFilteredList] = useState<ContactDetailsModel[]>([])
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const [errors, setErrors] = useState<Errors>({
    inputError: undefined,
    walletError: undefined
  })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [open, setOpen] = useState(false)
  const [manual, setManual] = useState(false)

  const selectedWalletBalance = selectedWallet.address
    ? wallets.find((wallet) => wallet.address === selectedWallet.address)?.balance
    : null

  const isOptedIn =
    selectedWallet.chain === ChainName.ALGORAND && selectedWallet.address
      ? asset === ChainAsset.QCAD
        ? Boolean(selectedWalletBalance?.qcad?.toString())
        : Boolean(selectedWalletBalance?.usdc?.toString())
      : true

  const qcadWalletBalance = selectedWalletBalance?.qcad ? selectedWalletBalance.qcad : 0
  const usdcWalletBalance = selectedWalletBalance?.usdc ? selectedWalletBalance.usdc : 0
  const selectedContact: ContactDetailsModel = recipientList.filter((r) => r.contactDetails.id === selectedContactId)[0]

  const handleRequest = async () => {
    let inputError
    let walletError
    if (Number(value) === 0 && isOptedIn) {
      inputError = 'Must be greater than 0'
    }
    if (!selectedWallet.address) {
      walletError = 'Please choose a wallet first'
    }
    if (!(inputError || walletError)) {
      setLoading(true)
      try {
        switch (selectedWallet.walletType) {
          case WalletType.METAMASK:
            await signMetamaskTransaction()
            break
        }
      } catch (err) {
        alertNotification('Generating transaction to sign failed. Please contact support.', 'error')
      }
      setLoading(false)
    }
    setErrors({ inputError, walletError })
  }

  const signMetamaskTransaction = async () => {
    if (ethereum) {
      try {
        const web3 = new Web3(ethereum)
        web3.eth.defaultAccount = selectedWallet.address
        const { contractAddress, adminAddress, amount, contractABI } =
          asset === ChainAsset.QCAD
            ? {
                contractABI: QCADcontractABI as AbiItem[],
                adminAddress: recipient,
                contractAddress: process.env.REACT_APP_QCAD_CONTRACT_ADDRESS,
                amount: Math.round(Number(value) * 100)
              }
            : {
                contractABI: USDCcontractABI as AbiItem[],
                adminAddress: recipient,
                contractAddress: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
                amount: Math.round(Number(value) * 1000000)
              }
        const contract = new web3.eth.Contract(contractABI, contractAddress)

        const encodedData = contract.methods.transfer(adminAddress, amount).encodeABI()
        const txParams = {
          from: selectedWallet.address,
          to: contractAddress,
          value: web3.utils.toHex(0),
          data: encodedData
        }
        setIsSignMetamaskOpen(true)
        const txHash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [txParams]
        })
        setIsSignMetamaskOpen(false)
        if (txHash) {
          setSuccess(`Assets sent successfully!`)
          setValue('')
          setRecipient('')
        }
      } catch (err) {
        setIsSignMetamaskOpen(false)
        alertNotification('Sign transaction failed. Please contact support.', 'error')
      }
    } else {
      alertNotification('Metamask not available. Please install Metamask browser extension and retry.', 'error')
    }
  }

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (Number(newValue) > 0 && errors.inputError) {
      setErrors((s) => ({ ...s, inputError: undefined }))
    }
    if (asset === ChainAsset.QCAD && Number(newValue) > qcadWalletBalance) {
      setErrors((s) => ({ ...s, inputError: 'Insufficient QCAD balance' }))
    }
    if (asset === ChainAsset.USDC && Number(newValue) > usdcWalletBalance) {
      setErrors((s) => ({ ...s, inputError: 'Insufficient USDC balance' }))
    }
    setValue(newValue)
  }

  const handleChangeRecipient = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecipient(event.target.value)
  }

  const handleSelectRecipient = (recipientId: string) => {
    setRecipient(recipientId)
    setOpen(false)
  }

  const handleRemoveRecipient = () => {
    setRecipient('')
  }

  const handleOpenBlockchain = (contactId: string) => {
    setSelectedContactId(contactId)
    setOpen(true)
  }

  const handleCloseBlockchain = () => {
    setOpen(false)
  }

  const [anchorElAsset, setAnchorElAsset] = useState<null | HTMLElement>(null)

  const handleOpenAssetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAsset(event.currentTarget)
  }

  const handleCloseAssetMenu = () => {
    setAnchorElAsset(null)
  }

  const chooseAsset = (asset: ChainAsset) => {
    setAsset(asset)
    setErrors((s) => ({ ...s, inputError: undefined }))
    handleCloseAssetMenu()
  }

  const fetchContacts = async () => {
    try {
      const recipients = await getContacts()
      setRecipientList(recipients.data)
      setFilteredList(recipients.data)
    } catch {
      alertNotification('Something went wrong when fetching contacts.')
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    setFilteredList(
      recipientList.filter((c) =>
        `${c.contactDetails.firstName} ${c.contactDetails.lastName}`
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      )
    )
  }

  const handleManual = () => {
    setManual(true)
  }

  const handleContacts = () => {
    setManual(false)
  }

  useEffect(() => {
    fetchContacts()
  }, [])

  useEffect(() => {
    setAsset(ChainAsset.USDC)
  }, [selectedWallet.chain])

  return {
    value,
    recipient,
    search,
    filteredList,
    selectedContact,
    asset,
    selectedWallet,
    selectedWalletBalance,
    loading,
    errors,
    anchorElAsset,
    success,
    open,
    manual,
    handleRequest,
    handleChangeRecipient,
    handleSelectRecipient,
    handleRemoveRecipient,
    handleAmountChange,
    handleOpenAssetMenu,
    handleCloseAssetMenu,
    chooseAsset,
    handleSearch,
    handleOpenBlockchain,
    handleCloseBlockchain,
    handleManual,
    handleContacts
  }
}

export default useNonCustodial
