import { LoadingButton } from '@mui/lab'
import { Grid, Link, MenuItem, Typography } from '@mui/material'
import { Field, Form } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as React from 'react'
import { Container } from 'src/ui'
import { apiSignUpDTO } from '../../../common/api/onboardApi/index'
import { State } from '../ApiOnboard.State'
import * as S from '../styles'

interface CompanyInformationProps {
  state: State
  submitForm: () => Promise<void>
  isSubmitting: boolean
  values: apiSignUpDTO
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({ state, submitForm, isSubmitting, values }) => {
  return (
    <Form>
      <Container maxWidth="600px" title="Request for Grapes sandbox access" error={state.error}>
        <S.Row>
          <Typography variant="body2">Please fill the required fields *</Typography>
        </S.Row>
        <S.Row>
          <S.InputLabel>Company *</S.InputLabel>
          <Field component={TextField} name="company" placeholder="Company" fullWidth />
        </S.Row>
        <S.Row>
          <S.InputLabel>Email *</S.InputLabel>
          <Field component={TextField} name="email" placeholder="Email" fullWidth />
        </S.Row>
        <S.Row>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <S.InputLabel>First name *</S.InputLabel>
              <Field component={TextField} name="firstName" placeholder="First Name" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <S.InputLabel>Last name *</S.InputLabel>
              <Field component={TextField} name="lastName" placeholder="Last Name" fullWidth />
            </Grid>
          </Grid>
        </S.Row>
        <S.Row>
          <S.InputLabel>Select your desired blockchains supported by Grapes API *</S.InputLabel>
          <Field
            component={Select}
            sx={{ minWidth: '200px' }}
            multiple
            value={values.blockchains}
            name="blockchains"
            autoWidth={true}
            label="Blockchain"
          >
            <MenuItem value="ETHEREUM">Ethereum</MenuItem>
            <MenuItem value="ALGORAND">Algorand</MenuItem>
            <MenuItem value="STELLAR">Stellar</MenuItem>
          </Field>
        </S.Row>
        <S.Row>
          <S.InputLabel>Please tell us how you would use Grapes API *</S.InputLabel>
          <Field component={TextField} name="productGoals" fullWidth multiline={true} minRows={3} />
        </S.Row>
        <S.Row>
          <LoadingButton variant="contained" size="large" loading={isSubmitting} onClick={submitForm} fullWidth>
            Submit
          </LoadingButton>
        </S.Row>
        <Typography variant="subtitle2" textAlign="center">
          By checking &Prime;Create Account&Prime; you confirm that you have read and agree to the{' '}
          <Link component="a" href="https://www.grapesfinance.com/tnc/" target="_blank" rel="noopener">
            Terms of Use
          </Link>{' '}
          &{' '}
          <Link component="a" href="https://www.grapesfinance.com/privacypolicy/" target="_blank" rel="noopener">
            Privacy Policy
          </Link>
          .
        </Typography>
      </Container>
    </Form>
  )
}

export default CompanyInformation
