import * as React from 'react'
import { Typography } from '@mui/material'
import { Container } from 'src/ui'
import { Formik } from 'formik'
import useApiOnboard, { initialValues } from './ApiOnboard.State'
import MobileDesktopNotification from '../MobileDesktopNotification'
import CompanyInformation from './form/CompanyInformation'
import { validate } from './ApiOnboard.State'

const ApiOnboard: React.FC = () => {
  const { state, onSubmit } = useApiOnboard()

  // check if user is on a mobile device
  const details = navigator.userAgent
  const regexp = /android|iphone|kindle|ipad/i
  const isMobileDevice = regexp.test(details)
  if (isMobileDevice) {
    return <MobileDesktopNotification />
  }

  return !state.success ? (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = validate(values)
        return errors
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <CompanyInformation state={state} submitForm={submitForm} isSubmitting={isSubmitting} values={values} />
      )}
    </Formik>
  ) : (
    <Container maxWidth="600px" title="Registration Successful" error={state.error}>
      <Typography variant="h5" align="center">
        Thank you! We will contact you via email with Developer Portal Access and Supporting Documentation.
      </Typography>
    </Container>
  )
}

export default ApiOnboard
